.music-timer {
    width: 100%;
    height: 4px;
    background-color: #141414;
    grid-row: 9/10;
}

.music-timer:hover {
	height: 12px;
}

.completed {
    height: 100%;
    width: 60%;
    background-color: #2a8eb9;  
}
